<template>
  <div class="navigation-card bg-white rounded-lg shadow-sm">
    <div class="navigation-card-header bg-info-light position-relative">
      <b-img-lazy
        id="navigation-image"
        class="position-absolute"
        :src="
          require(`@/assets/images/SparkMembers/Contents/views/List/components/LessonNavigationContainer/navigation.svg`)
        "
      />
    </div>
    <div class="py-3 px-4 text-dark">
      <h5 class="font-weight-bold mt-2 mb-3">
        {{ $t('sparkmembers.contents.views.list.components.main.components.lesson-navigation-card.title') }}
      </h5>
      <template v-if="step == 1">
        <div>
          {{ $t('sparkmembers.contents.views.list.components.main.components.lesson-navigation-card.description') }}
          <strong class="text-primary">
            {{
              linearRequirements
                ? $t(
                    'sparkmembers.contents.views.list.components.main.components.lesson-navigation-card.selected-completion-previous-lesson'
                  )
                : $t('sparkmembers.contents.views.list.components.main.components.lesson-navigation-card.selected-free')
            }}</strong
          >
        </div>

        <hs-button v-if="canEditProduct" class="mt-3" variant="outline-primary" @click="onChangeNavigation">{{
          $t(
            'sparkmembers.contents.views.list.components.main.components.lesson-navigation-card.buttons.change-navigation'
          )
        }}</hs-button>
      </template>
      <template v-if="step == 2">
        <hs-radio class="mt-3" v-model="linearRequirements" name="navigation-classes" :value="false">
          {{ $t('sparkmembers.contents.views.list.components.main.components.lesson-navigation-card.free') }}
        </hs-radio>
        <hs-radio class="mt-3" v-model="linearRequirements" name="navigation-classes" :value="true">
          {{
            $t(
              'sparkmembers.contents.views.list.components.main.components.lesson-navigation-card.completion-previous-lesson'
            )
          }}
        </hs-radio>
        <hs-button class="mt-3" variant="outline-secondary" @click="onSave">{{
          $t('sparkmembers.contents.views.list.components.main.components.lesson-navigation-card.buttons.save')
        }}</hs-button>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { analyticsService, courseService } from '@/services';
import ToastHelper from '@/shared/helpers/toast';

export default {
  name: 'LessonNavigationCard',
  data() {
    return {
      step: 1,
      linearRequirements: null,
    };
  },
  computed: {
    ...mapState({
      course: state => state.course.selectedCourse,
    }),
    canEditProduct() {
      return this.$ACL.canProductConfig();
    },
  },
  methods: {
    onChangeNavigation() {
      this.step++;
    },
    onSave() {
      const course = { ...this.course, linear_requirements: this.linearRequirements };
      courseService
        .update(course)
        .then(() => {
          ToastHelper.successMessage(
            this.$t(
              'sparkmembers.contents.views.list.components.main.components.lesson-navigation-card.toast.linear-requirements.update.success'
            )
          );
          this.step = 1;
          analyticsService.track({
            event: 'Lesson navigation updated',
            props: {
              navigation: this.linearRequirements ? 'paced' : 'freely',
            },
          });
        })
        .catch(() =>
          ToastHelper.dangerMessage(
            this.$t(
              'sparkmembers.contents.views.list.components.main.components.lesson-navigation-card.toast.linear-requirements.update.error'
            )
          )
        );
    },
  },
  async mounted() {
    this.linearRequirements = this.course.linear_requirements;
    await this.$nextTick();
  },
};
</script>

<style lang="scss" scoped>
.navigation-card {
  width: 286px;
  &-header {
    height: 118px;
  }

  #navigation-image {
    top: -20px;
  }
}
</style>
