<template>
  <draggable
    tag="ul"
    group="module"
    class="list-group"
    handle=".handle"
    v-bind="$attrs"
    v-on="$listeners"
    @start="drag = true"
    @end="drag = false"
  >
    <li
      :class="{ 'mb-4': module.parent_course_module === null }"
      class="list-group-module list-group-item bg-transparent rounded p-0 border-0"
      v-for="(module, index) in $attrs.value"
      :key="`module_${index}`"
    >
      <div class="list-group-module-header" @click="onOpenModule(module)">
        <div class="flex-1">
          <div class="d-flex align-items-center">
            <hs-icon variant="light" icon="bars" class="handle" />
            <div v-if="moduleToEdit && moduleToEdit.id === module.id" class="w-75 ml-2">
              <hs-input
                class="w-75 d-inline ml-2"
                :autofocus="true"
                :placeholder="$t(`${prefixLang}.input-module-name.placeholder`)"
                :text="$t(`${prefixLang}.input-module-name.text`)"
                :value="moduleToEdit.name"
                @keyup.enter="onUpdateModule(module, { name: $event.target.value })"
                @blur="onUpdateModule(module, { name: $event.target.value })"
              />
            </div>

            <span
              v-else
              :class="{
                'font-weight-bold': module.parent_course_module === null,
                'font-size-lg': module.parent_course_module === null,
              }"
              class="h-100 mb-0 text-break big-text-truncate overflow-hidden ml-3"
              v-b-tooltip.hover.bottom="$t(`${prefixLang}.edit-module-name`)"
              @click.stop="moduleToEdit = module"
            >
              {{ module.name }}
            </span>
          </div>
        </div>
        <div>
          <b-dropdown :disabled="!canEditProduct" variant="link" no-caret class="p-0 ml-2 mr-3">
            <template v-slot:button-content>
              <hs-icon
                class="font-size-lg mr-2"
                variant="light"
                :class="getModuleStatus(module.available).class"
                :icon="getModuleStatus(module.available).icon"
              />
              <p class="d-inline font-size-sm text-dark" v-if="!isMobile">
                {{ $t(`${prefixLang}.list-status.${getModuleStatus(module.available).locale}`) }}
              </p>
            </template>
            <b-dropdown-item
              v-for="list in listStatusModules"
              :key="list.text"
              class="font-size-sm"
              @click.stop="
                list.status !== null ? onUpdateModule(module, { available: list.status }) : onRemoveModule(index)
              "
            >
              <hs-icon class="font-size-lg mr-2" :class="list.class" variant="light" :icon="list.icon" />
              {{ list.text }}
            </b-dropdown-item>
          </b-dropdown>

          <hs-icon :icon="module.open ? 'chevron-up' : 'chevron-down'" />
        </div>
      </div>

      <b-collapse v-model="module.open">
        <template>
          <div class="ml-3 mb-1">
            <div class="py-1 bg-white">
              <NestedDraggableContents
                v-bind="dragOptions"
                v-model="module.contents"
                :loading="activeModuleId.includes(module.id)"
                @change="onUpdateContent"
              />
            </div>

            <div v-if="canEditProduct" class="d-flex justify-content-around bg-white rounded py-2 buttons-container">
              <div>
                <MButton
                  icon="plus"
                  variant="link"
                  :label="$t(`${prefixLang}.buttons.lesson`)"
                  :disabled="isInAction"
                  @click="onAddLesson(module)"
                />
              </div>

              <div v-if="showBtnAddSubModule">
                <MButton
                  variant="link"
                  icon="plus"
                  :label="$t(`${prefixLang}.buttons.sub-module`)"
                  :disabled="isInAction"
                  @click="onAddSubModule(module)"
                />
              </div>
            </div>
          </div>

          <NestedDraggableModules
            v-model="module.sub_modules"
            :showBtnAddSubModule="false"
            @change="onUpdateSubModules"
          />
        </template>
      </b-collapse>
    </li>
  </draggable>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import draggable from 'vuedraggable';
import { analyticsService, courseService, lessonService } from '@/services';
import ToastHelper from '@/shared/helpers/toast';
import browserHelper from '@/shared/helpers/browser';
import NestedDraggableContents from './NestedDraggableContents';
import Confirm from '@/shared/mixins/Confirm';
import MButton from '@/shared/components/MButton.vue';

export default {
  inheritAttrs: false,
  name: 'NestedDraggableModules',
  mixins: [Confirm],
  props: {
    showBtnAddSubModule: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      moduleToEdit: {},
      drag: false,
      isInAction: false,
      activeModuleId: [],
      prefixLang: 'sparkmembers.contents.views.list.components.main.components.nested-draggable-modules',
    };
  },
  components: {
    draggable,
    NestedDraggableContents,
    MButton,
  },
  computed: {
    isMobile() {
      return browserHelper.isMobile();
    },
    ...mapState({
      product: state => state.product.selectedProduct,
      course: state => state.course.selectedCourse,
      selectedSchool: state => state.school.selectedSchool,
    }),
    listStatusModules() {
      return [
        {
          text: this.$t(`${this.prefixLang}.list-status.to-publish`),
          icon: 'check-circle',
          class: 'text-success-dark',
          status: true,
        },
        {
          text: this.$t(`${this.prefixLang}.list-status.to-hide`),
          icon: 'times-circle',
          class: 'text-dark',
          status: false,
        },
        {
          text: this.$t(`${this.prefixLang}.list-status.to-delete`),
          icon: 'trash-alt',
          class: 'text-danger',
          status: null,
        },
      ];
    },
    dragOptions() {
      return {
        animation: 200,
        disabled: false,
        ghostClass: 'ghost',
      };
    },
    canEditProduct() {
      return this.$ACL.canProductConfig();
    },
  },
  methods: {
    ...mapActions('school', ['updateSchool']),
    getModuleStatus(available) {
      if (available || available === null) {
        return {
          class: 'text-success-dark',
          locale: 'published',
          icon: 'check-circle',
        };
      }
      return {
        class: 'text-dark',
        locale: 'hidden',
        icon: 'times-circle',
      };
    },
    async onOpenModule(module) {
      try {
        module.open = !module.open;

        if (
          module.id &&
          module.open &&
          module.contents.length === 0 &&
          module.sub_modules &&
          module.sub_modules.length === 0
        ) {
          this.isInAction = true;
          this.activeModuleId.push(module.id);

          const { course_contents } = await courseService.getCourseContents(module.id);
          const resultModules = await courseService.getCourseModules(this.course.id, [
            { key: 'parent_course_module_id', value: module.id },
          ]);

          module.contents = course_contents;
          module.sub_modules = resultModules.data.course_modules.map(sub => ({
            ...sub,
            open: false,
            contents: [],
            sub_modules: [],
          }));
        }
      } catch (error) {
        ToastHelper.dangerMessage(this.$t(`${this.prefixLang}.toast.open-module.error`));
      } finally {
        this.isInAction = false;
        this.activeModuleId = this.activeModuleId.filter(id => id !== module.id);
      }
    },
    async onUpdateModule(module, newProp = null) {
      if (newProp) {
        let [key, value] = Object.entries(newProp)[0];
        module[key] = value;
        await courseService.updateCourseModule(module);
        this.moduleToEdit = {};
      }
    },
    async onRemoveModule(index) {
      try {
        this.isInAction = true;
        const module = this.$attrs.value[index];

        const type =
          module.parent_course_module === null
            ? this.$t(`${this.prefixLang}.confirm-box.delete-module.module`)
            : this.$t(`${this.prefixLang}.confirm-box.delete-module.sub-module`);

        const confirmBoxOptions = {
          okTitle: this.$t(`${this.prefixLang}.confirm-box.delete-module.ok-title`, { type }),
          cancelTitle: this.$t(`${this.prefixLang}.confirm-box.delete-module.cancel-title`, { type }),
          contentTitle: this.$t(`${this.prefixLang}.confirm-box.delete-module.content-title`, { type }),
          contentDescription: this.$t(`${this.prefixLang}.confirm-box.delete-module.content-description`, { type }),
          variant: 'cherry',
          icon: 'trash-alt',
        };

        if (await this.showConfirmBox(confirmBoxOptions)) {
          const modulesLength = this.$attrs.value.length;

          await courseService.deleteCourseModule(module);
          this.$attrs.value.splice(index, 1);

          if (index < modulesLength - 1) {
            const modulesIds = this.$attrs.value.map(item => item.id);
            await courseService.normalizeCourseModulesOrder(modulesIds);
          }

          ToastHelper.successMessage(this.$t(`${this.prefixLang}.toast.module.delete.success`));
        }
      } catch (error) {
        ToastHelper.dangerMessage(this.$t(`${this.prefixLang}.toast.module.delete.error`));
      } finally {
        this.isInAction = false;
      }
    },
    onAddSubModule(module) {
      this.$set(module, 'isAddSubModule', true);
      this.activeModuleId.push(module.id);
      this.isInAction = true;

      courseService
        .createCourseModule(this.course.id, {
          name: `${this.$t(`${this.prefixLang}.new-sub-module`)} ${module.sub_modules.length + 1}`,
          course_id: this.course.id,
          order: module.sub_modules.length,
          parent_course_module_id: module.id,
        })
        .then(({ data }) => {
          module.sub_modules.push({
            ...data,
            contents: [],
            open: true,
          });
          ToastHelper.successMessage(this.$t(`${this.prefixLang}.toast.module.add.success`));
        })
        .catch(() => ToastHelper.dangerMessage(this.$t(`${this.prefixLang}.toast.module.add.error`)))
        .finally(() => {
          this.$set(module, 'isAddSubModule', false);
          this.activeModuleId = this.activeModuleId.filter(id => id !== module.id);
          this.isInAction = false;
        });
    },
    async onUpdateSubModules(action) {
      if (action.moved) {
        const parentModuleId = action.moved.element.parent_course_module.id;

        const ids = this.$attrs.value.find(item => item.id === parentModuleId).sub_modules.map(item => item.id);
        await courseService.normalizeCourseModulesOrder(ids);
      } else if (action.added) {
        const module = action.added.element;

        if (module.parent_course_module) {
          const idsMyOldSiblings = this.$attrs.value
            .find(item => item.id === module.parent_course_module.id)
            .sub_modules.map(item => item.id);
          await courseService.normalizeCourseModulesOrder(idsMyOldSiblings);

          const myNewParent = this.$attrs.value.find(mod => mod.sub_modules.some(sub_mod => sub_mod.id === module.id));

          await courseService.updateCourseModule({
            ...module,
            parent_course_module: { id: myNewParent.id, name: myNewParent.name },
            parent_course_module_id: myNewParent.id,
          });

          const idsMyNewSiblings = myNewParent.sub_modules.map(item => item.id);
          await courseService.normalizeCourseModulesOrder(idsMyNewSiblings);
        }
      }
    },
    async onAddLesson(module) {
      try {
        this.activeModuleId.push(module.id);
        this.isInAction = true;

        const lesson = await lessonService.create({
          title: `${this.$t(`${this.prefixLang}.new-lesson`)} ${module.contents.length + 1}`,
          type: 'ContentLesson',
        });

        const content = await courseService.createCourseContent(module.id, {
          order: module.contents.length,
          course_module_id: module.id,
          content_id: lesson.id,
          content_type: 'Lesson',
          available: true,
        });

        await lessonService.addCommentsInLesson({
          title: lesson.title,
          description: lesson.description,
          school_product_id: this.product.id,
          lesson_id: lesson.id,
        });

        const school = this.selectedSchool;
        school.extra_settings.show_top_bar = 'false';

        await this.updateSchool({ id: school.id, extra_settings: school.extra_settings });

        module.contents.push({ ...content, ...{ lesson } });

        ToastHelper.successMessage(this.$t(`${this.prefixLang}.toast.lesson.add.success`));

        analyticsService.track({
          event: 'Lesson created',
          props: {
            product_id: parseInt(this.$route.params.id),
            course_id: this.course.id,
            school_id: this.selectedSchool.id,
          },
        });
      } catch (error) {
        ToastHelper.dangerMessage(this.$t(`${this.prefixLang}.toast.lesson.add.error`));
      } finally {
        this.isInAction = false;
        this.activeModuleId = this.activeModuleId.filter(id => id !== module.id);
      }
    },
    async onUpdateContent(action) {
      try {
        if (action.moved) {
          const contentIds = await this.$attrs.value
            .find(module => module.id === action.moved.element.course_module_id)
            .contents.map(item => item.id);

          await courseService.normalizeCourseContentsOrder(contentIds);
        } else if (action.added) {
          const content = action.added.element;

          const newModule = this.$attrs.value.find(mod => mod.contents.some(cont => cont.id === content.id));
          const contentIds = newModule.contents.map(item => item.id);
          await courseService.normalizeCourseContentsOrder(contentIds);

          const course_content = { ...content, course_module_id: newModule.id };
          await courseService.updateCourseContent({ id: content.id, course_content });
          await lessonService.update(content.lesson);
          newModule.contents.forEach(content => (content.course_module_id = newModule.id));
        }
      } catch (error) {
        ToastHelper.dangerMessage(this.$t(`${this.prefixLang}.toast.content.update.error`));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.list-group-module {
  &-header {
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f1fbfc;
    min-height: 70px;
    padding: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    cursor: pointer;
  }

  .handle {
    cursor: grabbing;
  }

  .module-container {
    height: 70px;
  }

  .big-text-truncate {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  /deep/ .dropdown {
    .btn {
      padding: 0;
    }
  }

  .buttons-container {
    border: 1px dashed $cyan;

    /deep/ button {
      color: $cyan;

      &:hover {
        color: $blueish-dark;
      }

      &:disabled {
        color: #c6c6c6;
      }
    }
  }
}
</style>
