<template>
  <div class="row">
    <div class="col">
      <hs-loading v-if="isLoading" />
      <template v-else>
        <NestedDraggableModules
          data-track="course editor"
          v-bind="dragOptions"
          v-model="modules"
          @change="onUpdateModules"
        />

        <div class="d-flex mt-3 mb-5">
          <hsDashedButton class="text-primary" icon="plus" variant="primary" @click="onAddModule">
            {{
              isAddingModule
                ? $t('sparkmembers.contents.views.list.components.main.index.loading')
                : $t('sparkmembers.contents.views.list.components.main.index.add.buttons.module')
            }}</hsDashedButton
          >
        </div>
      </template>
    </div>
    <div class="col-lg-auto">
      <LessonNavigationCard />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { analyticsService, courseService, lessonService } from '@/services';
import ToastHelper from '@/shared/helpers/toast';
import { hsLoading } from '@/components';
import { hsDashedButton } from '@/components';
import LessonNavigationCard from './components/LessonNavigationCard';
import NestedDraggableModules from './components/NestedDraggableModules';

export default {
  name: 'ModuleLessonList',
  components: {
    hsDashedButton,
    hsLoading,
    NestedDraggableModules,
    LessonNavigationCard,
  },
  data() {
    return {
      isAddingModule: false,
      isLoading: true,
      modules: [],
    };
  },
  computed: {
    ...mapState({
      product: state => state.product.selectedProduct,
      course: state => state.course.selectedCourse,
    }),
    dragOptions() {
      return {
        animation: 400,
        group: 'description',
      };
    },
  },
  methods: {
    async onCreateInitialModules() {
      if (this.modules.length === 0) {
        this.isLoading = true;
        const { data: module } = await courseService.createCourseModule(this.course.id, {
          name: this.$t('sparkmembers.contents.views.list.components.main.index.add.initial.module'),
          course_id: this.course.id,
          order: 0,
        });

        this.modules.push({
          ...module,
          open: false,
          contents: [],
          sub_modules: [],
        });

        await this.onCreateLesson({ module_id: module.id, title: 'welcome', order: 0 });
        await this.onCreateLesson({ module_id: module.id, title: 'objectives', order: 1 });
        await this.onCreateLesson({ module_id: module.id, title: 'introduction', order: 2 });
        this.modules[0].open = true;
        this.isLoading = false;
      }
    },
    async onFetchModules() {
      try {
        const { data } = await courseService.getCourseModules(this.course.id, [{ key: 'roots', value: true }]);
        this.modules = data.course_modules.map(module => ({
          ...module,
          open: false,
          contents: [],
          sub_modules: [],
        }));
      } finally {
        this.isLoading = false;
      }
    },
    onAddModule() {
      this.isAddingModule = true;
      courseService
        .createCourseModule(this.course.id, {
          name: `${this.$t(
            'sparkmembers.contents.views.list.components.main.components.nested-draggable-modules.new-module'
          )} ${this.modules.length + 1}`,
          course_id: this.course.id,
          order: this.modules.length,
        })
        .then(async ({ data }) => {
          const modulesIds = this.modules.map(item => item.id);
          await courseService.normalizeCourseModulesOrder(modulesIds);

          this.modules.push({
            ...data,
            open: true,
            contents: [],
            sub_modules: [],
          });
          ToastHelper.successMessage(
            this.$t('sparkmembers.contents.views.list.components.main.index.toast.module.add.success')
          );

          analyticsService.track({
            event: 'Course module created',
            props: {
              product_id: parseInt(this.$route.params.id),
              course_id: this.course.id,
            },
          });
        })
        .catch(() =>
          ToastHelper.dangerMessage(
            this.$t('sparkmembers.contents.views.list.components.main.index.toast.module.add.error')
          )
        )
        .finally(() => (this.isAddingModule = false));
    },
    async onUpdateModules(action) {
      if (action.moved) {
        const ids = this.modules.map(item => item.id);
        await courseService.normalizeCourseModulesOrder(ids);
      } else if (action.added || action.removed) {
        const module = action.added ? action.added.element : action.removed.element;

        const parentModule = this.modules.find(mod => mod.sub_modules.some(sub_mod => sub_mod.id === module.id));

        if (parentModule) {
          const subModulesOfTheParentModule = parentModule.sub_modules;

          const newSubModule = subModulesOfTheParentModule.find(item => item.id === module.id);
          await courseService.updateCourseModule({
            ...newSubModule,
            parent_course_module: { id: parentModule.id, name: parentModule.name },
            parent_course_module_id: parentModule.id,
          });

          const subModulesOfTheParentModuleIds = subModulesOfTheParentModule.map(item => item.id);
          await courseService.normalizeCourseModulesOrder(subModulesOfTheParentModuleIds);
        } else {
          await courseService.updateCourseModule({
            ...module,
            parent_course_module: null,
            parent_course_module_id: null,
          });

          const oldParent = this.modules.find(item => item.id === module.parent_course_module.id);
          const idsMyOldSiblings = oldParent.sub_modules.map(item => item.id);
          await courseService.normalizeCourseModulesOrder(idsMyOldSiblings);

          const idsParentModules = this.modules.map(item => item.id);
          await courseService.normalizeCourseModulesOrder(idsParentModules);
        }

        await this.onFetchModules();
      }
    },
    async onCreateLesson({ module_id, title, order }) {
      try {
        const lesson = await lessonService.create({
          title: this.$t(`sparkmembers.contents.views.list.components.main.index.add.initial.lesson.${title}`),
          type: 'ContentLesson',
        });
        const content = await courseService.createCourseContent(module_id, {
          order,
          course_module_id: module_id,
          content_id: lesson.id,
          content_type: 'Lesson',
          available: true,
        });
        await lessonService.addCommentsInLesson({
          title: lesson.title,
          description: lesson.description,
          school_product_id: this.product.id,
          lesson_id: lesson.id,
        });

        this.modules[0].contents.push({ ...content, ...{ lesson } });
      } catch (error) {
        ToastHelper.dangerMessage(
          this.$t('sparkmembers.contents.views.list.components.main.index.toast.lesson.add.error')
        );
      }
    },
  },
  mounted() {
    setTimeout(async () => {
      try {
        await this.onFetchModules();
        await this.onCreateInitialModules();
      } catch (error) {
        ToastHelper.dangerMessage(
          this.$t('sparkmembers.contents.views.list.components.main.index.toast.module.add.error')
        );
      }
    }, 1000);
  },
};
</script>
