<template>
  <section class="d-flex flex-column">
    <hsPageHeader
      :title="$t('breadcrumb.ContentsList')"
      :back-text="$t('breadcrumb.ProductDashboard')"
      back-route="ProductDashboard"
    />
    <NavigationOptions v-model="activeItem" :contentOptions="contentOptions" />
    <div class="container-fluid d-flex flex-column flex-fill py-4 py-lg-5 px-3 px-md-5">
      <transition name="fade" mode="out-in">
        <component :is="activeItem" />
      </transition>
    </div>
  </section>
</template>

<script>
import hsPageHeader from '@/components/_structures/PageHeader';
import NavigationOptions from './components/NavigationOptions';
import Main from './components/Main';
import Additional from './components/Additional';
import Description from './components/Description';

export default {
  name: 'List',
  data() {
    return {
      activeItem: 'Main',
    };
  },
  components: {
    hsPageHeader,
    NavigationOptions,
    Main,
    Additional,
    Description,
  },
  computed: {
    contentOptions() {
      const contentOptions = [
        { title: this.$t('sparkmembers.contents.views.list.index.content-options.menu'), name: 'Main' },
        {
          title: this.$t('sparkmembers.contents.views.list.index.content-options.further-material'),
          name: 'Additional',
        },
      ];
      if (this.$FCL.hasMembersCoursePage()) {
        contentOptions.splice(1, 0, {
          title: this.$t('sparkmembers.contents.views.list.index.content-options.description'),
          name: 'Description',
        });
      }
      return contentOptions;
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      const { type } = from.query;
      vm.activeItem = !type ? 'Main' : type === 'Lesson' ? 'Main' : 'Additional';
      next();
    });
  },
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  margin-top: -30px;
}
</style>
