<template>
  <NoAccess v-if="hasNoAccess" />
  <router-view v-else></router-view>
</template>

<script>
import NoAccess from './views/NoAccess/index.vue';
import store from '@/store';
export default {
  components: {
    NoAccess,
  },
  data() {
    return {
      hasNoAccess: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.hasNoAccess = store.state.auth.loggedUser.role.name == 'Suporte ao Aluno';
      next();
    });
  },
};
</script>
