var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"navigation-card bg-white rounded-lg shadow-sm"},[_c('div',{staticClass:"navigation-card-header bg-info-light position-relative"},[_c('b-img-lazy',{staticClass:"position-absolute",attrs:{"id":"navigation-image","src":require("@/assets/images/SparkMembers/Contents/views/List/components/LessonNavigationContainer/navigation.svg")}})],1),_vm._v(" "),_c('div',{staticClass:"py-3 px-4 text-dark"},[_c('h5',{staticClass:"font-weight-bold mt-2 mb-3"},[_vm._v("\n      "+_vm._s(_vm.$t('sparkmembers.contents.views.list.components.main.components.lesson-navigation-card.title'))+"\n    ")]),_vm._v(" "),(_vm.step == 1)?[_c('div',[_vm._v("\n        "+_vm._s(_vm.$t('sparkmembers.contents.views.list.components.main.components.lesson-navigation-card.description'))+"\n        "),_c('strong',{staticClass:"text-primary"},[_vm._v("\n          "+_vm._s(_vm.linearRequirements
              ? _vm.$t(
                  'sparkmembers.contents.views.list.components.main.components.lesson-navigation-card.selected-completion-previous-lesson'
                )
              : _vm.$t('sparkmembers.contents.views.list.components.main.components.lesson-navigation-card.selected-free')))])]),_vm._v(" "),(_vm.canEditProduct)?_c('hs-button',{staticClass:"mt-3",attrs:{"variant":"outline-primary"},on:{"click":_vm.onChangeNavigation}},[_vm._v(_vm._s(_vm.$t(
          'sparkmembers.contents.views.list.components.main.components.lesson-navigation-card.buttons.change-navigation'
        )))]):_vm._e()]:_vm._e(),_vm._v(" "),(_vm.step == 2)?[_c('hs-radio',{staticClass:"mt-3",attrs:{"name":"navigation-classes","value":false},model:{value:(_vm.linearRequirements),callback:function ($$v) {_vm.linearRequirements=$$v},expression:"linearRequirements"}},[_vm._v("\n        "+_vm._s(_vm.$t('sparkmembers.contents.views.list.components.main.components.lesson-navigation-card.free'))+"\n      ")]),_vm._v(" "),_c('hs-radio',{staticClass:"mt-3",attrs:{"name":"navigation-classes","value":true},model:{value:(_vm.linearRequirements),callback:function ($$v) {_vm.linearRequirements=$$v},expression:"linearRequirements"}},[_vm._v("\n        "+_vm._s(_vm.$t(
            'sparkmembers.contents.views.list.components.main.components.lesson-navigation-card.completion-previous-lesson'
          ))+"\n      ")]),_vm._v(" "),_c('hs-button',{staticClass:"mt-3",attrs:{"variant":"outline-secondary"},on:{"click":_vm.onSave}},[_vm._v(_vm._s(_vm.$t('sparkmembers.contents.views.list.components.main.components.lesson-navigation-card.buttons.save')))])]:_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }