<template>
  <component v-if="type" :is="component" />
</template>
<script>
export default {
  name: 'ContentsEditor',
  data() {
    return {
      type: null,
    };
  },
  computed: {
    component() {
      return () => import(`./components/${this.type}`);
    },
  },
  mounted() {
    this.type = this.$route.query.type === 'Quiz' ? 'Lesson' : this.$route.query.type;

    if (!this.type) {
      this.$router.push({ name: 'Contents' });
    }
  },
};
</script>
