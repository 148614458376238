<template>
  <div class="list">
    <div v-if="isLoading" class="loading">
      <b-spinner variant="gray" small class="mr-2" />
      {{ $t('general.loading') }}...
    </div>

    <div v-if="!isLoading && !$attrs.value.length" class="p-3 text-center">
      Nenhuma aula cadastrada
    </div>

    <draggable v-else class="list-group" tag="ul" group="content" v-bind="$attrs" v-on="$listeners">
      <li
        class="list-group-item"
        v-for="(content, index) in $attrs.value"
        :key="`contents_${index}`"
        @drag="actionIndex = index"
        @mouseover="actionIndex = index"
        @mouseleave="actionIndex = -1"
        @click.stop="canEditProduct ? onOpenEditLesson(content.id) : {}"
      >
        <div class="list-group-item-content mr-4">
          <div class="d-flex align-items-center justify-content-center" style="width: 25px">
            <hs-icon
              class="font-size-lg"
              variant="light"
              :icon="index === actionIndex ? 'bars' : getContentIcon(content.lesson)"
            />
          </div>

          <div v-if="lessonToEdit && lessonToEdit.id === content.lesson.id" class="ml-2">
            <hs-input
              class="d-inline ml-2"
              :placeholder="$t(`${prefixLang}.input-content-name.placeholder`)"
              :text="$t(`${prefixLang}.input-content-name.text`)"
              :value="lessonToEdit.title"
              @click.stop=""
              @keyup.stop.enter="onUpdateLesson(content.lesson, { title: $event.target.value })"
              @blur.stop="onUpdateLesson(content.lesson, { title: $event.target.value })"
            />
          </div>

          <div v-else class="d-flex flex-1 ml-3">
            <div class="d-flex flex-column justify-content-center">
              <span class="mb-0 text-break big-text-truncate overflow-hidden">
                {{ content.lesson.title }}
              </span>

              <small class="d-block mt-n1" v-if="content.lesson.type !== 'ExamLesson' && !content.lesson.media">{{
                $t(`${prefixLang}.input-content-name.empty`)
              }}</small>
            </div>

            <button
              class="btn btn-link ml-3 p-0"
              @click.stop="lessonToEdit = content.lesson"
              v-b-tooltip.hover.bottom="$t(`${prefixLang}.edit-title`)"
            >
              <hs-icon variant="light" icon="pencil" class="font-size-lg" />
            </button>
          </div>
        </div>

        <div class="list-group-item-actions mx-3">
          <div class="list-group-item-actions-buttons" v-if="index === actionIndex">
            <MButton
              class="text-dark ml-2"
              variant="link"
              icon="eye"
              v-b-tooltip.hover.bottom="$t('actions.view')"
              @click.stop="onViewLesson(content)"
            />

            <MButton
              v-if="canEditProduct"
              class="text-dark ml-2"
              variant="link"
              icon="pencil"
              v-b-tooltip.hover.bottom="$t('actions.edit')"
              @click.stop="onOpenEditLesson(content.id)"
            />

            <MButton
              v-if="canEditProduct"
              class="text-danger ml-2 mr-3"
              variant="link"
              icon="trash-alt"
              v-b-tooltip.hover.bottom="$t('actions.delete')"
              @click.stop="onDeleteLesson(content)"
            />
          </div>

          <hs-icon
            class="font-size-lg"
            variant="light"
            v-b-tooltip.hover.bottom="content.available ? 'Publicada' : 'Ocultada'"
            :class="[isPublishedContent(content.available) ? 'text-success-dark' : 'text-danger']"
            :icon="isPublishedContent(content.available) ? 'check-circle' : 'times-circle'"
          />
        </div>
      </li>
    </draggable>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { mapState, mapGetters } from 'vuex';
import { courseService, lessonService } from '@/services';
import ToastHelper from '@/shared/helpers/toast';
import Confirm from '@/shared/mixins/Confirm';
import MButton from '@/shared/components/MButton.vue';
import { getAdminViewSsoUrl } from '@/shared/helpers/general';

export default {
  inheritAttrs: false,
  name: 'NestedDraggableContents',
  mixins: [Confirm],
  props: {
    loading: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  data() {
    return {
      actionIndex: -1,
      lessonToEdit: {},
      drag: false,
      prefixLang: 'sparkmembers.contents.views.list.components.main.components.nested-draggable-contents',
      isLoading: false,
    };
  },
  components: {
    draggable,
    MButton,
  },
  watch: {
    loading(value) {
      this.isLoading = value;
    },
  },
  computed: {
    ...mapGetters('school', ['getDomain']),
    ...mapState({
      loggedUser: state => state.auth.loggedUser,
      course: state => state.course.selectedCourse,
    }),
    canEditProduct() {
      return this.$ACL.canProductConfig();
    },
  },
  methods: {
    getContentIcon(lesson) {
      const type = lesson.type === 'ExamLesson' ? 'Lesson' : lesson.media ? lesson.media.type : 'Other';
      const types = ['Document', 'Video', 'Text', 'Image', 'Audio', 'Other', 'Lesson', 'WebinarHerospark'];
      const variants = ['file', 'video', 'text', 'file-image', 'microphone', 'engine-warning', 'file-alt', 'wifi'];

      return variants[types.indexOf(type)];
    },
    isPublishedContent(available) {
      return available || available === null;
    },
    onViewLesson(content) {
      const url = getAdminViewSsoUrl({
        domain: this.getDomain,
        user: this.loggedUser,
        redirectTo: `/admin_view/courses/${this.course.id}/course_contents/${content.id}`,
      });
      window.open(url, '_blank');
    },
    onOpenEditLesson(contentId) {
      this.$router.push({
        name: 'ContentEditor',
        params: { contentId },
        query: { type: 'Lesson' },
      });
    },
    async onUpdateLesson(lesson, newProp = null) {
      if (newProp) {
        let [key, value] = Object.entries(newProp)[0];
        lesson[key] = value;
        await lessonService.update(lesson);
        this.lessonToEdit = {};
      }
    },
    async onDeleteLesson(content) {
      try {
        const contentIndex = this.$attrs.value.findIndex(cont => cont.id === content.id);

        const confirmBoxOptions = {
          okTitle: this.$t(`${this.prefixLang}.confirm-box.delete-lesson.ok-title`),
          cancelTitle: this.$t(`${this.prefixLang}.confirm-box.delete-lesson.cancel-title`),
          contentTitle: this.$t(`${this.prefixLang}.confirm-box.delete-lesson.content-title`),
          contentDescription: this.$t(`${this.prefixLang}.confirm-box.delete-lesson.content-description`),
          variant: 'cherry',
          icon: 'trash-alt',
        };

        if (await this.showConfirmBox(confirmBoxOptions)) {
          this.isLoading = true;

          await courseService.deleteCourseContent(content.id, content);
          await lessonService.delete(content.lesson);

          if (contentIndex < this.$attrs.value.length - 1) {
            const { course_contents } = await courseService.getCourseContents(content.course_module_id);
            const contentIds = course_contents.map(item => item.id);
            await courseService.normalizeCourseContentsOrder(contentIds);
          }

          this.$attrs.value.splice(contentIndex, 1);

          ToastHelper.successMessage(this.$t(`${this.prefixLang}.toast.lesson.delete.success`));
        }
      } catch (error) {
        ToastHelper.dangerMessage(this.$t(`${this.prefixLang}.toast.lesson.delete.error`));
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.loading {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background: rgba($color: #fff, $alpha: 0.5);
}

.list {
  position: relative;
  min-height: 55px;
}

.list-group-item {
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  cursor: pointer;

  &-content {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-self: start;
  }

  &-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-width: 160px;

    &-buttons {
      display: flex;
      align-items: center;
    }
  }
}

.ghost {
  opacity: 0.5;
  background: $grey-20;
}

.big-text-truncate {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.content-icon {
  width: 20px;
  &:hover {
    cursor: grab;
  }
}
</style>
