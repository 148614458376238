<template>
  <div class="row">
    <div class="description-container col-12 col-sm-12 p-4 md:tlw-bg-white">
      <b-form @submit.prevent="">
        <hs-group class="mb-0">
          <div>
            <label class="font-weight-bold">{{
              $t('sparkmembers.contents.views.list.components.description.index.title')
            }}</label>
            <span class="optional font-size-xs">{{
              $t('sparkmembers.contents.views.list.components.description.index.optional')
            }}</span>
          </div>
          <p class="description-text font-size-sm m-0 pb-4">
            {{ $t('sparkmembers.contents.views.list.components.description.index.description') }}
          </p>
          <div>
            <Editor
              v-model="form.description"
              :initial-value="form.description || ''"
              :init="{ ...editorDefaultInit }"
            />
          </div>
          <div class="admin-informative d-flex align-items-center p-3 rounded-sm mt-3">
            <hs-icon icon="exclamation-circle" :size="24" />
            <span class="ml-2">{{
              $t('sparkmembers.contents.views.list.components.description.index.informative')
            }}</span>
          </div>
          <MButton
            class="form-button mt-4 float-right"
            variant="primary"
            :label="$t('sparkmembers.contents.views.list.components.description.index.btn-save')"
            @click="updateDescription"
          >
          </MButton>
        </hs-group>
      </b-form>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import Editor from '@tinymce/tinymce-vue';
import { editorDefaultInit } from '@/libs/tinymce';
import MButton from '@/shared/components/MButton.vue';
import ToastHelper from '@/shared/helpers/toast';

export default {
  components: { Editor, MButton },
  data() {
    return {
      form: {
        description: '',
      },
      editorDefaultInit: {
        ...editorDefaultInit,
        content_style: `body { font-family: Helvetica, Arial, sans-serif; font-size: 1rem; ${
          this.$FCL.hasThemeLightMode() ? '' : 'background-color: #262626; color: #fff;'
        }}`,
      },
    };
  },
  created() {
    this.form.description = this.course.description || '';
  },
  computed: {
    ...mapState('course', {
      course: state => state.selectedCourse,
    }),
  },
  methods: {
    ...mapActions('course', ['updateCourse']),
    async updateDescription() {
      try {
        await this.updateCourse({ ...this.course, description: this.form.description });
        ToastHelper.successMessage(this.$t('sparkmembers.contents.views.list.components.description.toast.success'));
      } catch (error) {
        ToastHelper.dangerMessage(this.$t('sparkmembers.contents.views.list.components.description.toast.success'));
      }
    },
  },
};
</script>
<style lang="scss">
.description-container {
  border-radius: 4px;
  .optional {
    color: $color-neutral-600;
  }
  .description-text {
    color: $grey-40;
  }

  @media screen and (max-width: $screen-sm) {
    .form-button {
      width: 100%;
    }
  }
  .admin-informative {
    background-color: #e3e3e3;
    i,
    span {
      color: #525252;
    }
  }
}
</style>
