import { render, staticRenderFns } from "./NestedDraggableContents.vue?vue&type=template&id=9611328c&scoped=true&"
import script from "./NestedDraggableContents.vue?vue&type=script&lang=js&"
export * from "./NestedDraggableContents.vue?vue&type=script&lang=js&"
import style0 from "./NestedDraggableContents.vue?vue&type=style&index=0&id=9611328c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9611328c",
  null
  
)

export default component.exports