





































































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import productService from '@/sparkmembers/services/product';
import pathService from '@/sparkmembers/services/path';

import PageLayout from '@/layout/PageLayout.vue';
import PageHeader from '@/components/_structures/PageHeader.vue';
import Loading from '@/components/Loading.vue';
import MButton from '@/shared/components/MButton.vue';
import ToastHelper from '@/shared/helpers/toast';
import { unionBy } from 'lodash';

import { Path as IPath, PathProduct } from '@/types/path';

const ProductModule = namespace('product');
const CourseModule = namespace('course');

enum IsLoading {
  DONE = 'done',
  LOADING = 'loading',
  SEARCHING = 'searching',
  REMOVING = 'removing',
  UPDATING = 'updating',
  PAGING = 'paging',
}

@Component({
  components: {
    PageLayout,
    PageHeader,
    MButton,
    Loading,
    PathsTableCourses: () => import('./components/PathsTableCourses.vue'),
    PathsModalAddProducts: () => import('./components/PathsModalAddProducts.vue'),
  },
})
export default class Paths extends Vue {
  @CourseModule.State('selectedPath') path!: IPath;
  @ProductModule.State('selectedProduct') product!: any;

  products: PathProduct[] = [];

  loading: IsLoading = IsLoading.LOADING;

  modalAddProducts: boolean = false;

  totalProducts: number = 0;
  currentPage: number = 1;
  perPage: number = 50;

  get canEditProduct() {
    return this.$ACL.canProductConfig();
  }

  mounted() {
    window.scrollTo(0, 0);
    this.getProducts();
  }

  async getProducts(page = 1) {
    try {
      const query = [{ key: 'page', value: page }, { key: 'per_page', value: this.perPage }];
      const pathId = this.$route.params.id;

      const { courses, total_count, total_pages, current_page } = await productService.getProductCourses(query, pathId);

      this.products = unionBy(this.products, this.formatedProductsData(courses), 'id');
      if (current_page < total_pages) {
        await this.getProducts(page + 1);
      }
      this.totalProducts = total_count;
    } catch (error) {
      ToastHelper.dangerMessage(this.$t('sparkmembers.contents.views.paths.toast.success.get-products'));
    } finally {
      this.loading = IsLoading.DONE;
    }
  }

  formatedProductsData(products: any[]): PathProduct[] {
    return products.map(({ id, name, kind }) => ({
      id,
      name,
      kind: this.$t(`sparkmembers.v2-product-list.${kind}`),
    }));
  }

  openModalAddProducts() {
    this.modalAddProducts = true;
    this.$bvModal.show('paths-modal-add-products');
  }

  async updatePath(courses) {
    try {
      this.loading = IsLoading.UPDATING;
      const courseIds = courses.map(({ id }) => id);
      const payload = {
        ...this.path,
        courses,
        course_ids: courseIds,
      };

      await pathService.update(payload);
      this.products = courses;

      ToastHelper.successMessage(this.$t('sparkmembers.contents.views.paths.toast.success.path-updated'));
    } catch (err) {
      ToastHelper.dangerMessage(this.$t('sparkmembers.contents.views.paths.toast.error.path-updated'));
    } finally {
      this.loading = IsLoading.DONE;
      this.modalAddProducts = false;
    }
  }

  async removeProduct(product) {
    try {
      this.loading = IsLoading.REMOVING;

      await pathService.deletePathCourse(this.path.id, product.id);

      this.products = this.products.filter(p => p.id !== product.id);

      ToastHelper.successMessage(this.$t('sparkmembers.contents.views.paths.toast.success.path-updated'));
    } catch (error) {
      ToastHelper.dangerMessage(this.$t('sparkmembers.contents.views.paths.toast.error.remove-product'));
    } finally {
      this.loading = IsLoading.DONE;
    }
  }
}
