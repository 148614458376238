<template>
  <section>
    <hsPageHeader
      :title="product.title"
      :back-text="$t(`sparkmembers.coupons.views.list.components.page-header.back-button-label`)"
      back-route="ProductDashboard"
    >
    </hsPageHeader>
    <section class="no-access h-100 py-6 mx-auto">
      <div class="d-flex flex-column align-items-center text-center py-4 mx-6">
        <img width="180" heigth="180" :src="mainIllustration" alt="no-access" />
        <h3 class="font-weight-bold my-2">{{ $t('sparkmembers.contents.views.no-access.title') }}</h3>
        <h5 v-html="$t('sparkmembers.contents.views.no-access.subtitle')"></h5>
        <MButton
          v-if="!isCombo"
          class="mt-4"
          :label="$t('sparkmembers.contents.views.no-access.btn-content')"
          variant="primary-outline"
          icon="eye"
          size="md"
          @click="redirectTo"
        />
      </div>
    </section>
  </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import hsPageHeader from '@/components/_structures/PageHeader';
import MButton from '@/shared/components/MButton.vue';
import { getAdminViewSsoUrl } from '@/shared/helpers/general';

export default {
  components: {
    hsPageHeader,
    MButton,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters('school', ['getDomain']),
    ...mapState({
      product: state => state.product.selectedProduct,
      course: state => state.course.selectedCourse,
      loggedUser: state => state.auth.loggedUser,
    }),
    isCourseOrLives() {
      return this.course.kind == 'online_course' || this.course.kind == 'live_meet';
    },
    isCombo() {
      return this.product.library_resource.resource.type == 'path';
    },
    mainIllustration() {
      return require('@/assets/images/SparkMembers/Contents/views/Editor/no-access.svg');
    },
  },
  methods: {
    redirectTo() {
      let route = this.isCourseOrLives
        ? `/admin_view/courses/${this.course.id}`
        : `/admin_view/courses/${this.course.id}/course_contents/${this.$route.params.contentId}`;
      window.open(
        getAdminViewSsoUrl({
          domain: this.getDomain,
          user: this.loggedUser,
          redirectTo: route,
        }),
        '_blank'
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.no-access {
  background-color: $grey-16;
}
</style>
